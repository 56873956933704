<template>
    <div>
      <h2>Plakát / emléklap</h2>
      <image-galery subfolder="archive2023"></image-galery>
  
      <h2>Programok</h2>
      <div>
        <h3>Kedves Párok!</h3>
        <p>
          Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire. Az utóbbi hetek esős időjárása kellően előkészítetette a terepet az idei mottó aktualitására: "Szeretetbe kapaszkodva".
          Az egymásba kapaszkodás nem csak a túra során hasznos. Lényegében mindegyik programunk, az előadás, a közös ima, a fogadalmunk megújítása, a gyertyafényes vacsora ebben az összekapaszkodásban próbál segíteni nektek.
        </p>
        <p>
          Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. 
          Ha eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] 
          gmail.com</a> címre küldött e-maillel.
        </p>
        <p>
          A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében. Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!!
          Tudjuk, sokaknak nehéz megoldani a gyerekvigyázást, amikor el szeretnének jutni valamelyik programra. Tudunk segíteni, ez ügyben keressétek Hollai Zsófit a +36204404580-as telefonszámon!
        </p>
        <p>
          Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.
        </p>
        
        <p class="right-align">HH Team</p>
        
        <h2>Túra kettesben</h2>
        <div class="list-wrapper">
          <h3>Kedves Házasok!</h3>
          <p>
          Idén sem hagyunk benneteket otthon csücsülni!!! Három kirándulást (könnyű, közepes és nehéz) készítettünk elő nektek, egyet 
          mindenképp csináljatok meg, de ha lehetőségetek, időtök engedi, vagy annyira belejöttök, semmi sem akadályozzon meg Benneteket 
          abban, hogy akár mind a három túrát is végigjárjátok. A térképek segítségével könnyen odataláltok majd. A természetjáró 
          applikációt a telefonotokra letöltve akár úgy is használhatjátok a térképeket, hogy online módon odavezet benneteket a célpontokhoz.
          </p> 
          <p>
          A Római Katolikus templomban a padok mögött, illetve az Állatorvosi Rendelő - Radnóti M.u.1. - bejáratánál elhelyeztünk egy-egy 
          ládát, amit teafilterekkel töltöttünk fel. A filterek ugyanolyanok, de a hozzájuk csatolt idézetek mások. Nézzétek végig az 
          összeset, a teafiltereket vigyétek magatokkal az útra, az idézeteket pedig őrizzétek meg és hozzátok majd el a gyertyafényes vacsorára!
          </p>
          <p>
          Vigyetek tehát magatokkal egy termosz forró vizet és 2 bögrét, s a célállomáson elkészített, gőzölgő teával a kezetekben reméljük 
          egy jót tudtok beszélgetni a kiválasztott idézetről. Kérünk Benneteket, hogy erről a meghitt, gőzölgő teával a kezetekben megélt 
          pillanatról készítsetek egy szerelmes szelfit. S ha kedvetekre van, küldjétek el nekünk a fotót a 
          <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre.
          </p>
          <p>Íme a túrák:</p>
          <embedded-map src="https://www.termeszetjaro.hu/hu/embed/259118101/js?mw=false" title="Könnyű útvonal"></embedded-map>
          <embedded-map src="https://www.termeszetjaro.hu/hu/embed/259118906/js?mw=false" title="Közepes nehézségű útvonal"></embedded-map>
          <embedded-map src="https://www.termeszetjaro.hu/hu/embed/259117722/js?mw=false" title="Nehéz útvonal"></embedded-map>
        </div>

        <h2>2. Előadás</h2>
        <div class="list-wrapper">
          <p>
            <strong>Szeretetbe kapaszkodva:</strong>
            <br>Dobó István és Judit, Burger Domonkos és Luca
          </p>
          <p>
            <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
            <br> február 14. kedd este 18.00
          </p>
        </div>

        <h2>3. Közös imádság</h2>
        <div class="list-wrapper">
          <p>
            <strong>Nagymaros, Római Katolikus Templom</strong>
            <br> február 16. csütörtök este 20.00
          </p>
        </div>

        <h2>4. Házassági fogadalom megújítása</h2>
        <div class="list-wrapper">
          <p>
            <strong>Nagymaros, Római Katolikus Templom</strong>
            <br> február 18. szombat este 18.30
          </p>
          <p>
            <strong>Nagymaros, Református Templom</strong>
            <br> február 19. vasárnap délelőtt 10.00
          </p>
        </div>

        <h2>5. Gyertyafényes vacsora</h2>
        <div class="list-wrapper">
          <p> Nagyon szeretnénk idén gyertyafényes vacsorát szervezni, kérünk jelezzétek részvételi szándékotokat a 
            <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel.
          </p>
          <p>
            <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
            <br> február 18. szombat este 20.00
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import EmbeddedMapComponent from './EmbeddedMapComponent.vue';
    import ImageGaleryComponent from './ImageGaleryComponent.vue';
  
    export default {
      name: 'archive-2023',
      components: {
        'embedded-map': EmbeddedMapComponent,
        'image-galery': ImageGaleryComponent
      },
      data: function() {
        return {
          publicPath: process.env.BASE_URL
        }
      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .download-icon {
        width: 44px;
        float: left;
        margin-top: 26px;
        margin-right: 8px;
        padding-right: 4px;
    }

    .sign {
        float: left;
        width: 168px;
        margin-top: 24px;
        margin-right: 8px;
        padding-right: 4px;
    }

    @media (max-width: 920px) {
        .sign {
            width: 150px;
        }
    }
  
    h2:first-child, h3:first-child {
      margin-top: 20px;
    }
  </style>
  